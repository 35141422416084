<template>
	<div class="content-wrap">
		<Spin fix v-if="loading || initLoading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem
				:span="1"
				label="项目"
				v-if="!$route.params.projectId && !initLoading"
			>
				<Select
					v-model="searchParams.projectId"
					:transfer="true"
					clearable
					filterable
					placeholder="请选择项目"
				>
					<Option
						:value="list.projectId"
						v-for="list in projectList"
						:key="list.projectId"
						:label="list.projectName"
					>
						<span
							style="
								display: inline-block;
								max-width: 500px;
								white-space: normal;
								word-break: break-all;
							"
						>
							{{ list.projectName }}</span
						>
					</Option>
				</Select>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="开始结束日期" placement="top">
				<DatePicker
					type="daterange"
					placeholder="请选择开始结束日期"
					:transfer="true"
					style="width: 100%"
					format="yyyy-MM-dd"
					:value="times"
					@on-change="v => (times = v)"
				></DatePicker>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="操作人真实姓名" placement="top">
				<Input
					v-model="searchParams.operatorRealName"
					clearable
					placeholder="请输入操作人真实姓名"
				></Input>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem
				:span="1"
				label="类别"
				v-if="!$route.params.projectId && !initLoading"
			>
				<Select
					v-model="searchParams.category"
					:transfer="true"
					clearable
					placeholder="请选择操作类别"
					@on-change="changeCategory"
				>
					<Option
						:value="list"
						v-for="(list, index) in categoryList"
						:key="index"
						>{{ list }}
					</Option>
				</Select>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="操作类型">
				<Select
					:disabled="!$route.params.projectId && !isShowOperatorType"
					v-model="searchParams.operatorType"
					:transfer="true"
					placeholder="请先选择类别"
				>
					<Option :value="list" v-for="(list, index) in typeList" :key="index"
						>{{ list }}
					</Option>
				</Select>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button @click="exportLogs">导出操作记录</Button>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
			ref="table"
		></CtmsDataGrid>
	</div>
</template>

<script>
import api from "@/api/log/log"
import { mapState } from "vuex"
import XLSX from "xlsx"
import Moment from "moment"

const {
	apiGetPage,
	getTypeList,
	getcategoryList,
	getProjectList,
	getProjTypeList
} = api

export default {
	name: "index",
	data() {
		return {
			listData: [],
			columns: [
				{
					title: "操作类型",
					key: "operatorType",
					minWidth: 150
				},
				{
					title: "操作人",
					key: "operatorName",
					minWidth: 170
				},
				{
					title: "内容",
					key: "operatorContent",
					minWidth: 300
				},
				{
					title: "时间",
					key: "operatorTime",
					minWidth: 180
				}
			],
			isShowOperatorType: false,
			loading: false,
			initLoading: false,
			addVisible: false,
			viewVisible: false,
			updateId: "",
			viewId: "",
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			categoryList: [],
			typeList: [],
			projectList: [],
			times: [],
			searchParams: {
				category: "",
				operatorRealName: "",
				operatorType: "",
				projectId: this.$route.params.projectId
					? this.$route.params.projectId
					: "",
				timeFrom: this.times ? this.times[0] : "",
				timeTo: this.times ? this.times[1] : ""
			},
			oldSearchParams: {
				projectId: this.$route.params.projectId
					? this.$route.params.projectId
					: ""
			}
		}
	},
	created() {
		if (!this.$route.params.projectId) {
			const item = {
				title: "类别",
				key: "category",
				minWidth: 150
			}
			this.columns.unshift(item)
		}
		this.getTypeList()
		this.initList()
	},
	computed: {
		...mapState("project", ["infoHeight", "projectInfo"]),
		...mapState({
			dataGridHeight(state) {
				if (this.$route.params.projectId) {
					return state.contentHeight - 146 - this.infoHeight
				}
				return state.contentHeight - 130
			}
		})
	},
	methods: {
		getTypeList() {
			this.$asyncDo(async () => {
				this.initLoading = true

				const res = await getcategoryList()
				// 项目下的类型
				const ret = await getProjTypeList({
					projectId: this.$route.params.projectId
				})
				if (res) {
					this.categoryList = res.data
				}
				// 项目列表
				const proj = await getProjectList()
				if (ret) {
					this.typeList = ret.data
				}

				if (proj) {
					this.projectList = proj.data
				}
				this.initLoading = false
			})
		},
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPage({
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res) {
					this.listData = res.data.list
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		changeCategory(v) {
			if (v) {
				this.isShowOperatorType = true
				this.$asyncDo(async () => {
					const res = await getTypeList({ category: v })
					if (res) {
						this.typeList = res.data
					}
				})
			} else {
				this.isShowOperatorType = false
			}
			this.searchParams.operatorType = ""
		},
		// 导出操作记录
		exportLogs() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPage({
					projectId: this.$route.params.projectId
						? this.$route.params.projectId
						: "",
					pageNum: 0,
					pageSize: 10 * 10000
				})
				if (res) {
					const headers = {
						category: "操作类型",
						operatorName: "操作人",
						operatorContent: "内容",
						operatorTime: "时间"
					}
					const data = res.data.list
					const dateStr = Moment().format("YYYY-MM-DD HH:mm:ss")
					let fileName = `系统操作记录_${dateStr}.xlsx`
					if (this.$route.params.projectId) {
						fileName = `项目${this.projectInfo.productName}_${dateStr}_操作记录.xlsx`
					}
					const exportData = []
					exportData.push(Object.values(headers))
					data.forEach(d => {
						const item = []
						Object.keys(headers).forEach(k => {
							item.push(d[k])
						})
						exportData.push(item)
					})
					const sheet = XLSX.utils.aoa_to_sheet(exportData)
					const book = XLSX.utils.book_new()
					XLSX.utils.book_append_sheet(book, sheet, "系统操作记录")
					XLSX.writeFile(book, fileName)
				}
				this.loading = false
			})
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 重置
		reset() {
			this.isShowOperatorType = false
			this.times = []
			this.searchParams = {
				category: "",
				operatorRealName: "",
				operatorType: "",
				projectId: this.$route.params.projectId
					? this.$route.params.projectId
					: ""
			}
			this.searchHandle()
		},
		searchHandle() {
			Object.assign(this.searchParams, {
				timeFrom: this.times ? this.times[0] : "",
				timeTo: this.times ? this.times[1] : ""
			})
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		}
	},
	watch: {}
}
</script>
