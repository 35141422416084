import request from "@/utils/request"
import commonApi from "@/api/api"

const moduleName = "/common/note"

export default {
	...commonApi(moduleName),
	getcategoryList: data =>
		request(`${moduleName}/system-category`, { method: "get", params: data }),
	getTypeList: data =>
		request(`${moduleName}/system-operator-type`, {
			method: "get",
			params: data
		}),
	getProjectList: () => request("/projects", { method: "get" }),
	getProjTypeList: data =>
		request(`${moduleName}/project-operator-type`, {
			method: "get",
			params: data
		})
}
