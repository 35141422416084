import request from "@/utils/request"
import commonApi from "@/api/api"

// 经费管理接口
const moduleName = "/project"

export default {
	...commonApi(moduleName),
	// 获取角色，主要研究者 roleId为10
	getResearchUers: data =>
		request("/auth/users/special-role", { method: "get", params: data }),
	// 获取所有医院的科室
	apiGetDeparts: () => request(`/auth/dept/org`, {
    method: "get",
    params: {
      orgId: 1
    }
  }),
	// 获取经费管理列表
	apiGetFundsList: (projectId, data) =>
		request(`/project/${projectId}/outlay/list`, {
			method: "get",
			params: data
		}),
	// 获取经费管理列表 分页
	apiGetFundsListPage: (projectId, data) =>
		request(`/project/${projectId}/outlay/list-page`, {
			method: "get",
			params: data
		}),
	// 获取新增经费的项目基本信息
	apiGetFundsProDetail: projectId =>
		request(`/project/${projectId}/outlay/project_basic_data`, {
			method: "get"
		}),
	// 查看经费详情
	apiGetFundsDetail: data =>
		request(`/project/${data.projectId}/outlay/${data.outlayId}`, {
			method: "get"
		}),
	// 新增经费
	addFunds: data =>
		request(`/project/${data.projectId}/outlay`, {
			method: "post",
			body: data
		}),
	// 修改经费
	editFunds: data =>
		request(`/project/${data.projectId}/outlay`, { method: "put", body: data }),
	// 删除经费
	deleteFunds: data =>
		request(`/project/${data.projectId}/outlay/${data.outlayId}`, {
			method: "delete"
		}),
	// 上传经费文件
	uploadFundsFile: (projectId, data) =>
		request(`/project/${projectId}/outlay/upload`, {
			method: "post",
			body: data
		}),
	// 经费報表 查看经费详情
	apiGetReportFundsDetail: data =>
		request("/report/outlay/detail", { method: "get", params: data }),
	// // 经费報表 列表
	// apiGetReportFundsList: data => request('/report/outlay/list', { method: 'get', params: data }),
	// 经费報表 列表分页
	apiGetReportFundsListPage: data =>
		request("/report/outlay/list-page", {
			method: "get",
			params: data
		}),
	// 经费审核
	apiExamine: data =>
		request(`/project/${data.projectId}/outlay/approve`, {
			method: "put",
			body: data
		}),
	// 获取入账出账
	apiGetMoney: (projectId, data) =>
		request(`/project/${projectId}/outlay/total`, {
			method: "get",
			params: data
		})
}
